<template>
	<div>
		<search-header></search-header>
		<div class="container">
			<div class="row border rounded mt-3 mb-3">
				<div class="w-100 ml-3 mr-3 mt-3">
					<b-breadcrumb :items="map"></b-breadcrumb>
				</div>
				<div class="col-1">
					<span>您的问题</span>
				</div>
				<div class="col-11">
					<input v-model="para.title" class="form-control mr-sm-2" maxlength="50" type="text"
						placeholder="请用一句话简单概述您的问题，最多不超过50个汉字">
				</div>
				<div class="col-1 mt-3">
					<span>补充问题</span>
				</div>
				<div class="col-11 mt-3">
					<div class="editor border rounded w-100" style="height: 160px;position: relative;z-index: 1;">
						<div ref="toolbar" class="toolbar">
						</div>
						<div ref="editor" class="text">
						</div>
					</div>
				</div>
				<div class="col-1 mt-3">
					<span>问题分类</span>
				</div>
				<div class="col-11 mt-3">
					<b-dropdown variant="outline-success" :text="firstName">
						<b-dropdown-item @click="firstName = m.name,para.classId = m.code" v-for="(m,i) in classList" :key="i" :active="para.classId == m.code">{{m.name}}</b-dropdown-item>
					</b-dropdown>
					<b-dropdown :text="lastName" variant="outline-success" class="ml-3">
						<b-dropdown-item @click="lastName = m.name,para.childClassId = m.code"  v-for="(m,i) in getChildClassList"  :active="para.childClassId == m.code" :key="i">{{m.name}}</b-dropdown-item>
					</b-dropdown>
				</div>
				<div class="col-11 mt-3 mb-4">
					<b-button @click="submitQuestion" variant="success" style="width: 256px;">提交问题</b-button>
				</div>
			</div>
		</div>
		<map-footer></map-footer>
	</div>
</template>

<script>
	import E from 'wangeditor'
	export default {
		data() {
			return {
				editor: null,
				map: [{
						text: '问答首页',
						href: '/ask'
					},
					{
						text: '我要提问',
						active: true
					}
				],
				classList: [],
				firstName: '请选择大类',
				lastName: '请选择小类',
				para:{
					title: '',
					content: '',
					classId: '',
					adoptAnswerId: '',
					childClassId: ''
				}
			}
		},
		computed: {
			getChildClassList () {
				if(this.para.classId > 0) {
					let m = this.classList.find(x=> x.code == this.para.classId);
					return m ? m.children : [];
				}
				return []
			}
		},
		mounted() {
			this.seteditor()
		},
		created() {
			this.getClass();
		},
		methods: {
			verify() {
				if(this.para.title.trim() == '') {
					this.$model.toast('请填写您的问题',2000);
					return false;
				} else {
					if(this.para.title.trim().length < 5) {
						this.$model.toast('您的问题不能少于5个汉字',2000);
						return false;
					}
				}
				if(!(this.para.classId > 0 && this.para.childClassId > 0)) {
					this.$model.toast('请选择问题分类',2000);
					return false;
				}
				return true;
			},
			getClass() {
				this.$http.post(this.$api.ask.getClass,{searchParam:''}).then(res => {
					this.classList = res.result;
				})
			},
			submitQuestion() {
				if(this.verify()) {
					this.$http.post(this.$api.ask.addQuestion,this.para).then(res => {
						if(res.code  == 1) {
							this.$model.toast('提问成功',2000);
							this.editor.txt.clear()
							this.para.content = '';
							this.para.title = ''
						} else {
							this.$model.toast(res.reason,2000);
						}
					}).catch(error => {
						this.$model.toast(error.reason,2000);
					})
				} 
			},
			seteditor() {
				this.editor = new E(this.$refs.toolbar, this.$refs.editor)
				
				this.editor.config.menus = [
					'head',
					'bold',
					'fontSize',
					'underline',
					'strikeThrough',
					'indent',
					'foreColor',
					'backColor',
					'undo',
					'redo',
				]
				// 创建富文本编辑器
				
				this.editor.config.onchangeTimeout = 1000
				this.editor.config.onchange = (html) => {
					this.para.content = html // 绑定当前逐渐地值
				}
				this.editor.create()
			}
		}
	}
</script>

<style>
</style>
